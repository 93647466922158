<template>
  <div>
    <div>
      <feather-icon
        icon="CalendarIcon"
        size="18"
        class="text-muted cursor-pointer"
      />
      <span>
        <b-dropdown
          :text="datelabel"
          variant="transparent"
          class="chart-dropdown"
          toggle-class="p-50"
        >
          <b-dropdown-item @click="selectdate(0)"> วันนี้</b-dropdown-item>
          <b-dropdown-item @click="selectdate(1)">เมื่อวาน</b-dropdown-item>
          <b-dropdown-item @click="selectdate(2)"
            >7 วันที่ผ่านมา</b-dropdown-item
          >
          <b-dropdown-item @click="selectdate(3)"
            >30 วันที่ผ่านมา</b-dropdown-item
          >
          <b-dropdown-item @click="selectdate(4)">เดือนนี้</b-dropdown-item>
          <b-dropdown-item @click="selectdate(5)">เดือนที่แล้ว</b-dropdown-item>
          <b-dropdown-item @click="selectdate(6)">ปีนี้</b-dropdown-item>
          <b-dropdown-item @click="selectdate(7)">ปีที่แล้ว</b-dropdown-item>
          <b-dropdown-item @click="selectdate(8)">กำหนดเอง</b-dropdown-item>
        </b-dropdown>
        <!--/ title and dropdown -->
        <hr />
        <b-form-group v-if="customdate">
          <h5>ระบุช่วงวันที่</h5>
          <flat-pickr
            v-model="rangeDate"
            class="form-control"
            :config="{ mode: 'range' }"
            @input="onDateChange"
          />
        </b-form-group>

        <p v-if="fromDate">ตั้งแต่ {{ fromDate }} ถึง {{ toDate }}</p>
      </span>
      <br />

      <div align="center" v-if="Loading">
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div v-else>
        <br />
        <b-row>
          <b-col xl="3" md="12">
            <div>
              <b-card align="center"
                ><br />
                <feather-icon icon="UsersIcon" size="50" />
                <h4>ผู้ใช้ทั้งหมด{{ wifi_users.user_all }} ผู้ใช้งาน</h4>
                <hr />
                <b-row>
                  <b-col xl="6" md="6">
                    <br />
                    <b-img :src="phone" width="100px" />
                    <h4>เบอร์โทร {{ wifi_users.total_phone }}</h4>
                  </b-col>
                  <b-col xl="6" md="6">
                    <br />
                    <b-img :src="img_email" width="100px" />

                    <h4>อีเมล {{ wifi_users.total_email }}</h4>
                  </b-col>
                </b-row><br><br>
              </b-card>
            </div>
          </b-col>
          <b-col xl="3" md="12">
            <b-card>
              <div align="center"><h3>การสมัครสมาชิก</h3></div>
              <vue-apex-charts
                type="donut"
                height="300"
                class="my-1"
                :options="sessionsByDeviceDonut.chartOptions"
                :series="sessionsByDeviceDonut.series"
              />
            </b-card>
          </b-col>
          <b-col xl="6" md="12">
            <b-card>
              <div align="center"><h3>กราฟผู้ใช้งาน</h3></div>
              <vue-apex-charts
                type="line"
                height="240"
                :options="salesLine.chartOptions"
                :series="salesLine.series"
              /><br>
            </b-card>
          </b-col>
        </b-row>
        <!-- --------------- -->
        <b-row>
          <b-col xl="4" md="12">
            <b-card>
              <h4 align="center">ประเภทอุปกรณ์</h4>
              <br /><br /><br />
              <b-row>
                <b-col xl="4" md="12" align="center">
                  <b-img :src="tablet" width="100px" /><br />
                  {{ wifi_users.d_tablet }}<br />
                  <b>Tablet</b>
                </b-col>
                <b-col xl="4" md="12" align="center">
                  <b-img :src="mobile" width="100px" /><br />
                  {{ wifi_users.d_mobile }}<br />
                  <b>Mobile</b>
                </b-col>
                <b-col xl="4" md="12" align="center">
                  <b-img :src="desktop" width="100px" /><br />
                  {{ wifi_users.d_desktop }}<br />
                  <b>LARGE SCREEN</b>
                </b-col> </b-row
              ><br /><br /><br />
            </b-card>
          </b-col>

          <b-col xl="4" md="12">
            <b-card no-body>
              <br />
              <div align="center"><h3>อุปกรณ์</h3></div>
              <b-card-body>
                <!-- apex chart -->
                <vue-apex-charts
                  type="donut"
                  height="350"
                  class="mt-2 mb-1"
                  :options="osPie.chartOptions"
                  :series="osPie.series"
                />
              </b-card-body>
            </b-card>
          </b-col>
          <b-col xl="4" md="12">
            <b-card no-body>
              <br />
              <div align="center"><h3>รุ่นอุปกรณ์</h3></div>
              <b-card-body>
                <!-- apex chart -->
                <vue-apex-charts
                  type="donut"
                  height="350"
                  class="mt-2 mb-1"
                  :options="device_model.chartOptions"
                  :series="device_model.series"
                /> </b-card-body
              ><br /><br />
            </b-card>
          </b-col>
        </b-row>
        <!-- --------------- -->
        <b-row>
          <b-col xl="6" md="12">
            <b-card class="chat-widget" no-body
              ><br />
              <div align="center">
                <h4>การเชื่อมไวไฟ</h4>
              </div>

              <section class="chat-app-window">
                <vue-perfect-scrollbar
                  :settings="perfectScrollbarSettings"
                  class="user-chats scroll-area"
                >
                  <div class="chats">
                    <div>
                      <div
                        class="chat-body"
                        v-for="(item, index) in branch"
                        :key="index"
                      >
                        <div>
                          <b-media no-body>
                            <b-media-aside class="mr-75">
                              <b-avatar rounded size="80" :src="house_wifi" />
                            </b-media-aside>
                            <b-media-body class="my-auto">
                              <h5 class="mb-0">
                               <b> {{ item.name }}
                                {{ item.total_connectWIFI }} ครั้ง</b>
                              </h5>
                              <!-- <small> employee.designation </small> -->
                            </b-media-body>
                          </b-media>
                          
                          <hr />
                        </div>
                      </div>
                    </div>
                  </div>
                </vue-perfect-scrollbar>
              </section>
              <br />
              <br /><br /><br />
            </b-card>
          </b-col>
          <b-col xl="6" md="12">
            <b-card>
              <b-row>
                <b-col xl="12" md="12">
                  <div align="center">
                    <h3>เพศ</h3>
                    <b-row>
                      <b-col xl="6" md="6">
                        <h4>หญิง {{ wifi_users.gender_female }} คน</h4>
                        <h4></h4
                      ></b-col>
                      <b-col xl="6" md="6">
                        <h4>ชาย {{ wifi_users.gender_male }} คน</h4>
                        <h4></h4
                      ></b-col>
                    </b-row>
                  </div>
                  <hr />

                  <h5 align="center">กราฟแท่งแสดงช่วงอายุ</h5>

                  <div>
                    <age :wifi_users="wifi_users"></age>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <!-- --------------- -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BCardBody,
  BProgress,
  BBadge,
  BFormGroup,
  BImg,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import api from "@/api";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import flatPickr from "vue-flatpickr-component";
import Age from "./AgeChart.js";
import mobile from "@/assets/images/non/mobile.svg";
import desktop from "@/assets/images/non/desktop.svg";
import tablet from "@/assets/images/non/tablet.svg";
import phone from "@/assets/images/non/phone.svg";
import img_email from "@/assets/images/non/email.svg";
import house_wifi from "@/assets/images/non/house_wifi.svg";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BCardBody,
    VueApexCharts,
    VuePerfectScrollbar,
    BProgress,
    BBadge,
    BFormGroup,
    BImg,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    flatPickr,
    Age,
  },
  setup() {
    return {
      mobile,
      desktop,
      tablet,
      phone,
      img_email,
      house_wifi,
    };
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 250,
        wheelPropagation: false,
      },
      datelabel: "7 วันที่ผ่านมา",
      customdate: false,
      fromDate: null,
      toDate: null,
      daterange: 2,
      rangeDate: null,
      name_user: JSON.parse(localStorage.getItem("userData")),
      branch: null,
      os: null,
      pieChartData: null,
      wifi_users: null,
      sessionsByDeviceDonut: {
        series: [],
        chartOptions: {
          labels: ["from", "line"],
          dataLabels: {
            enabled: true,
          },
          colors: [$themeColors.warning, $themeColors.primary],
        },
      },
      salesLine: {
        series: [
          {
            name: "ผู้ใช้งาน",
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: "line",
            dropShadow: {
              enabled: true,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
            offsetX: -10,
          },
          stroke: {
            curve: "smooth",
            width: 4,
          },
          grid: {
            borderColor: "#ebe9f1",
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: ["#eeb95f", "#30eb4a"],
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              inverseColors: false,
              gradientToColors: [$themeColors.primary],
              shadeIntensity: 1,
              type: "horizontal",
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: "#b9b9c3",
                fontSize: "0.857rem",
              },
            },
            axisTicks: {
              show: false,
            },
            categories: [
              "ม.ค.",
              "ก.พ.",
              "มี.ค. ",
              "เม.ย. ",
              "พ.ค. ",
              "มิ.ย. ",
              "ก.ค. ",
              "ส.ค.",
              "ก.ย.",
              "ต.ค.",
              "พ.ย.",
              "ธ.ค.",
            ],
            axisBorder: {
              show: false,
            },
            tickPlacement: "on",
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: "#b9b9c3",
                fontSize: "0.857rem",
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val;
              },
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
      device_model: {
        series: [],
        chartOptions: {
          labels: [],
          dataLabels: {
            enabled: true,
          },
          colors: [],
        },
      },
      osPie: {
        series: [],
        chartOptions: {
          labels: [],
          dataLabels: {
            enabled: true,
          },
          colors: [],
        },
      },
      Loading: false,
      analytics: null,

      revenueReport: {
        series: [
          {
            name: "Earning",
            data: [95, 177, 284, 256, 105, 63, 168, 218, 72],
          },
          {
            name: "Expense",
            data: [-145, -80, -60, -180, -100, -60, -85, -75, -100],
          },
        ],
        chartOptions: {
          chart: {
            stacked: true,
            type: "bar",
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false },
            },
          },
          xaxis: {
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
            ],
            labels: {
              style: {
                colors: "#b9b9c3",
                fontSize: "0.86rem",
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.primary, $themeColors.warning],
          plotOptions: {
            bar: {
              columnWidth: "17%",
              endingShape: "rounded",
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: "#b9b9c3",
                fontSize: "0.86rem",
              },
            },
          },
        },
      },
    };
  },
  beforeMount() {
    this.selectdate(this.daterange);
  },
  computed: {},
  mounted() {},
  methods: {
    onDateChange() {
      console.log(this.rangeDate);
      if (this.rangeDate.search("to") > 0) {
        const myArr = this.rangeDate.split(" to ");
        this.fromDate = myArr[0];
        this.toDate = myArr[1];
        this.$nextTick(() => {
          this.loaddata();
        });

        console.log(this.fromDate);
        console.log(this.toDate);
      }
    },
    selectdate(daterange) {
      this.loaded = false;
      this.daterange = daterange;
      // console.log("daterange :>> ", daterange);
      var today = this.$moment();
      if (daterange == 0) {
        this.customdate = false;
        this.datelabel = "วันนี้";
        this.fromDate = today.format("YYYY-MM-DD");
        this.toDate = today.format("YYYY-MM-DD");
        this.$nextTick(() => {
          this.loaddata();
        });
        console.log(this.fromDate);
        console.log(this.toDate);
      } else if (daterange == 1) {
        this.customdate = false;
        this.datelabel = "เมื่อวาน";

        this.fromDate = today.subtract(1, "days").format("YYYY-MM-DD");
        this.toDate = this.fromDate;
        this.$nextTick(() => {
          this.loaddata();
        });

        console.log(this.fromDate);
        console.log(this.toDate);
      } else if (daterange == 2) {
        this.datelabel = "7 วันที่ผ่านมา";

        this.fromDate = today.subtract(6, "days").format("YYYY-MM-DD");
        this.toDate = this.$moment().format("YYYY-MM-DD");
        this.$nextTick(() => {
          this.loaddata();
        });

        console.log(this.fromDate);
        console.log(this.toDate);
      } else if (daterange == 3) {
        this.customdate = false;

        this.datelabel = "30 วันที่ผ่านมา";

        this.fromDate = today.subtract(30, "days").format("YYYY-MM-DD");
        this.toDate = this.$moment().format("YYYY-MM-DD");
        this.$nextTick(() => {
          this.loaddata();
        });

        console.log(this.fromDate);
        console.log(this.toDate);
      } else if (daterange == 4) {
        this.customdate = false;
        this.datelabel = "เดือนนี้";
        this.fromDate = this.$moment().startOf("month").format("YYYY-MM-DD");
        this.toDate = this.$moment().endOf("month").format("YYYY-MM-DD");
        console.log(this.fromDate);
        console.log(this.toDate);
        this.$nextTick(() => {
          this.loaddata();
        });
      } else if (daterange == 5) {
        this.customdate = false;
        this.datelabel = "เดือนที่แล้ว";
        this.fromDate = this.$moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        this.toDate = this.$moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
        console.log(this.fromDate);
        console.log(this.toDate);
        this.$nextTick(() => {
          this.loaddata();
        });
      } else if (daterange == 6) {
        this.customdate = false;
        this.datelabel = "ปีนี้";
        this.fromDate = this.$moment().startOf("year").format("YYYY-MM-DD");
        this.toDate = this.$moment().endOf("year").format("YYYY-MM-DD");
        console.log(this.fromDate);
        console.log(this.toDate);
        this.$nextTick(() => {
          this.loaddata();
        });
      } else if (daterange == 7) {
        this.customdate = false;
        this.datelabel = "ปีที่แล้ว";
        this.fromDate = this.$moment()
          .subtract(1, "year")
          .startOf("year")
          .format("YYYY-MM-DD");
        this.toDate = this.$moment()
          .subtract(1, "year")
          .endOf("year")
          .format("YYYY-MM-DD");
        console.log(this.fromDate);
        console.log(this.toDate);
        this.$nextTick(() => {
          this.loaddata();
        });
      } else if (daterange == 8) {
        this.customdate = true;
        this.datelabel = "กำหนดเอง";
        this.fromDate = null;
        this.toDate = null;

        console.log(this.fromDate);
        console.log(this.toDate);
      } else {
        this.fromDate = null;
        this.toDate = null;
      }

      // console.log(currentDateWithFormat);
    },
    async loaddata() {
      this.Loading = true;
      const params = {
        store_id: localStorage.getItem("store_id"),
        user_main: this.name_user.user_main,
        fromDate: this.fromDate,
        toDate: this.toDate,
      };
      api
        .post("report_all", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.data :>> ", response.data);
          this.branch = response.data.branch;
          this.analytics = response.data.analytics;
          this.os = response.data.os;
          this.pieChartData = response.data.pieChartData;
          this.wifi_users = response.data.wifi_users[0];

          var sessionsByDeviceDonut_series = [];
          sessionsByDeviceDonut_series.push(this.wifi_users.registerFrom);
          sessionsByDeviceDonut_series.push(this.wifi_users.registerLine);

          this.sessionsByDeviceDonut = {
            series: sessionsByDeviceDonut_series,
            chartOptions: {
              labels: ["from ", "line "],
              dataLabels: {
                enabled: false,
              },
              colors: [$themeColors.warning, $themeColors.primary],
            },
          };

          this.sessionsByDeviceDonut = {
            ...this.sessionsByDeviceDonut,
            ...{
              series: sessionsByDeviceDonut_series,
              chartOptions: {
                labels: ["from ", "line "],
                dataLabels: {
                  enabled: true,
                },
                colors: this.analytics.model.datasets[0].backgroundColor,
                colorsText: this.analytics.model.datasets[0].backgroundColor,
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: "2rem",
                          fontFamily: "Montserrat",
                        },
                        value: {
                          fontSize: "1rem",
                          fontFamily: "Montserrat",
                          formatter(val) {
                            // eslint-disable-next-line radix
                            return `${parseInt(val)}`;
                          },
                        },
                        total: {
                          show: false,
                          fontSize: "1.5rem",
                          label: "Operational",
                          formatter() {
                            return "31%";
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          };
          var salesLine = [];
          salesLine.push(this.wifi_users.Month1);
          salesLine.push(this.wifi_users.Month2);
          salesLine.push(this.wifi_users.Month3);
          salesLine.push(this.wifi_users.Month4);
          salesLine.push(this.wifi_users.Month5);
          salesLine.push(this.wifi_users.Month6);
          salesLine.push(this.wifi_users.Month7);
          salesLine.push(this.wifi_users.Month8);
          salesLine.push(this.wifi_users.Month9);
          salesLine.push(this.wifi_users.Month10);
          salesLine.push(this.wifi_users.Month11);
          salesLine.push(this.wifi_users.Month12);

          this.salesLine = {
            ...this.salesLine,
            ...{
              series: [
                {
                  data: salesLine,
                },
              ],
            },
          };

          console.log(" this.analytics :>> ", this.analytics);
          this.osPie = {
            ...this.osPie,
            ...{
              series: this.analytics.brand.datasets[0].data,
              chartOptions: {
                labels: this.analytics.brand.labels,
                dataLabels: {
                  enabled: true,
                },
                colors: this.analytics.brand.datasets[0].backgroundColor,
                colorsText: this.analytics.brand.datasets[0].backgroundColor,
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: "2rem",
                          fontFamily: "Montserrat",
                        },
                        value: {
                          fontSize: "1rem",
                          fontFamily: "Montserrat",
                          formatter(val) {
                            // eslint-disable-next-line radix
                            return `${parseInt(val)}`;
                          },
                        },
                        total: {
                          show: false,
                          fontSize: "1.5rem",
                          label: "Operational",
                          formatter() {
                            return "31%";
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          };
          this.device_model = {
            ...this.device_model,
            ...{
              series: this.analytics.model.datasets[0].data,
              chartOptions: {
                labels: this.analytics.model.labels,
                dataLabels: {
                  enabled: true,
                },
                colors: this.analytics.model.datasets[0].backgroundColor,
                colorsText: this.analytics.model.datasets[0].backgroundColor,
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: "2rem",
                          fontFamily: "Montserrat",
                        },
                        value: {
                          fontSize: "1rem",
                          fontFamily: "Montserrat",
                          formatter(val) {
                            // eslint-disable-next-line radix
                            return `${parseInt(val)}`;
                          },
                        },
                        total: {
                          show: false,
                          fontSize: "1.5rem",
                          label: "Operational",
                          formatter() {
                            return "31%";
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          };
          this.Loading = false;
        })
        .catch((error) => {});
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/base/pages/app-chat-list.scss";
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fe718d;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
